import settings from './constants/settings';
import { state } from './state';
import { getFrameElement } from "./getters";

import { toggleBotFrameDiv, toggleBody, toggleHelpingBanner, toggleDivFrame } from './ui';

function postMessageToIframe(action, payload) {
  const frameElement = getFrameElement().contentWindow;

  frameElement.postMessage({
    action: action,
    message: payload
  }, settings.host);
}

function registerBotMessageAttributeListener() {
  const elementList = document.querySelectorAll('[data-bot-message]');

  for (const element of elementList) {
    element.addEventListener('click', function () {
      postMessageWithAttributeValue(element);
    })
  }
}

function postMessageWithAttributeValue(element) {
  const elementAttrMessage = element.getAttribute('data-bot-message');
  postMessageToIframe('widget:message', elementAttrMessage);
}

function registerBotActionListener() {
  const elementList = document.querySelectorAll('[data-bot-action]')
  for (const element of elementList) {
    element.addEventListener('click', function () {
      const botAction = element.getAttribute('data-bot-action');
      onBotAction(botAction);
    })
  }
}

function init(config) {
  state.setConfig(config);
  createElements();
  registerPostMessageListener();
  registerBotMessageAttributeListener();
  registerBotActionListener();
}

function createElements() {
  createDivFrame();
  createiFrame();
}

function createiFrame() {
  const botIframe = document.createElement('iframe');
  botIframe.id = settings.frameId;
  botIframe.name = settings.frameId;
  botIframe.scrolling = 'no';
  const frameDiv = document.getElementById(settings.frameDivId);
  frameDiv.appendChild(botIframe);
  window.open(settings.host, settings.frameId)
}

function createDivFrame() {
  const divHolder = document.createElement('div');
  divHolder.id = settings.frameDivId;
  divHolder.className = settings.frameDivClass;
  document.body.appendChild(divHolder);
}

function registerPostMessageListener() {
  window.addEventListener("message", onMessage, false);
}

function toggleBot(action) {
  toggleHelpingBanner(action);
  toggleBotFrameDiv(action);
  toggleBody(action);
  state.setSessionActive(!state.isSessionActive());
}

function reloadFrame() {
  window.open(settings.host, settings.frameId);
}

function onBotAction(action) {
  switch (action) {
    case 'restart':
      toggleBot('close');
      toggleDivFrame('close');
      reloadFrame();
      break;
    case 'focus':
      if (state.isFrameOpen()) {
        return;
      }
      toggleDivFrame()
      postMessageToIframe('widget:maximize', null);
      break;
    case 'sent_message':
      const inputId = state.getConfig().messageInputId;
      if (inputId) {
        const messageInputElm = document.getElementById(inputId);
        postMessageToIframe('widget:message', messageInputElm.value);
        messageInputElm.value = '';
      } else {
        console.error('[BotError:Config]: property \'messageInputId\' is not set.');
      }
      break;
  }
}

function onMessage(event) {
  if (settings.host === event.origin) {
    switch (event.data) {
      case 'widget:open':
        if (!state.isSessionActive()) {
          toggleBot();
        }
        state.setFrameOpen(true);
        toggleDivFrame();
        break;
      case 'widget:minimize':
        state.setFrameOpen(false);
        toggleDivFrame('close');
        break;
      case 'widget:close':
        state.setFrameOpen(false);
        toggleBot('close');
        toggleDivFrame('close');
        break;
    }
  }
}

export default {init};
