import settings from './constants/settings';

function getFrameElement() {
  const frameElement = document.getElementById(settings.frameId);
  if (!frameElement) {
    return null;
  }

  return frameElement;
}

export { getFrameElement }
