"use strict"
import widget from './widget';

if (window.insBotWidget || (window.insBotWidget = widget) && window.insBotWidgetSettings) {
    if (document.readyState === 'complete') {
        console.log('Script done with settings', window.insBotWidgetSettings);
    } else {
        document.onreadystatechange = () => {
            if (document.readyState === 'complete') {
                widget.init(window.insBotWidgetSettings);
            }
        }
    }
}
