import { state } from "./state";
import settings from "./constants/settings";

function toggleHelpingBanner(action) {
  const config = state.getConfig();
  const helpingBannerElm = document.getElementById(config.helpingBannerId);
  const htmlClass = 'bot-session--active';
  if (action === 'close') {
    helpingBannerElm.classList.remove(htmlClass);
    return;
  }
  helpingBannerElm.classList.add(htmlClass);
}

function toggleBotFrameDiv(action) {
  const botFrameDiv = document.getElementById(settings.frameDivId);
  const htmlClass = 'bot-widget-active';
  if (action === 'close') {
    botFrameDiv.classList.remove(htmlClass);
    return;
  }
  botFrameDiv.classList.add(htmlClass);
}

function toggleBody(action) {
  const body = document.getElementsByTagName('body')[0];
  const htmlClass = 'bot-widget-open';

  if (action === 'close') {
    body.classList.remove(htmlClass);
    return;
  }
  body.classList.add(htmlClass);
}

function getFrameDiv() {
  return document.getElementById(settings.frameDivId);
}

function toggleDivFrame(action) {
  const frameDiv = getFrameDiv();
  const htmlClass = 'bot-widget-open'
  if (action === 'close') {
    frameDiv.classList.remove(htmlClass);
    return;
  }
  frameDiv.classList.add(htmlClass);
}

export { toggleHelpingBanner, toggleBody, toggleBotFrameDiv, getFrameDiv, toggleDivFrame };
