import settings from "./constants/settings";

let store = {
  config: null,
  messageTriggerId: settings.messageTriggerId,
  messageInputId: settings.messageInputId,
  sessionActive: false,
  loaded: false,
  frameOpen: false
}

let state = {
  setConfig: (value) => {
    store.config = value;
  },
  getConfig: () => {
    return store.config;
  },
  setMessageTriggerId: (value) => {
    store.messageTriggerId = value;
  },
  setMessageInputId: (value) => {
    store.messageInputId = value;
  },
  setLoaded: (value) => {
    store.loaded = value;
  },
  isLoaded: () => {
    return store.loaded;
  },
  setSessionActive: (value) => {
    store.sessionActive = value;
  },
  isSessionActive: () => {
    return store.sessionActive;
  },
  setFrameOpen: (value) => {
    store.frameOpen = value;
  },
  isFrameOpen: () => {
    return store.frameOpen;
  }
}

export { state }
